import { Mention, MentionsInput } from "react-mentions";
import { autocompleteUsersWithCallback } from "../api";
import classNames from "../styles/mentions.module.css";

type CommentInputProps = {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  isEdit?: boolean;
};

export default function CommentInput({
  value,
  onChange,
  disabled = false,
  isEdit = false,
}: CommentInputProps) {
  return (
    <MentionsInput
      autoFocus={isEdit}
      className="mentions text-2xs"
      classNames={classNames}
      disabled={disabled}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      style={{
        minHeight: "60px",
      }}
    >
      <Mention
        trigger="@"
        data={(query, callBack) =>
          autocompleteUsersWithCallback(query, callBack)
        }
        style={{
          backgroundColor: "rgb(229 231 235)",
        }}
        appendSpaceOnAdd
        className={classNames.mentions__mention}
      />
    </MentionsInput>
  );
}
